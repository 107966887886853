import http from "./http"
import {apis} from "../global/config.js"

export class RequestCenter{
	getAll(){
		return http({
			url:apis.getAll,
			method:'get'
		})
	}
	webLogin(){
		return http({
			url:apis.webLogin,
			method:'get'
		})
	}
	deleteLoginWxMiniCodeImg(params){
		return http({
			url:apis.deleteLoginWxMiniCodeImg,
			method:"get",
			params:params,
		})
	}
	checkWebToken(){
		return http({
			url:apis.checkWebToken,
			method:"get"
		})
	}
	defaultListTag(){
		return http({
			url:apis.defaultListTag,
			method:"get"
		})
	}
	collegeList(){
		return http({
			url:apis.collegeList,
			method:"get",
		})
	}

	//上传文件
	uploadImg(file){
		return http({
			url:apis.uploadImg,
			method:"post",
			data:file
		})
	}

	//删除文件
	removeImg(fileName){
		return http({
			url:apis.removeImg,
			method:"get",
			params: {fileName:fileName}
		})
	}

	//修改用户信息
	userInfo(data){
		return http({
			url:apis.userInfo,
			method:"post",
			data:data,
		})
	}

	//获取用户信息
	getUserInfo(){
		return http({
			url:apis.getUserInfo,
			method:"get",
		})
	}
	//获取首页信息
	getIndexInfo(params){
		return http({
			url:apis.getIndexInfo,
			method:"get",
			params:params,
		})
	}
	//获取首页热度排行榜
	getIndexPopularityInfo(params){
		return http({
			url:apis.getIndexPopularityInfo,
			method:"get",
			params:params
		})
	}
	//点赞或取消
	addOrCancelLike(params){
		return http({
			url:apis.addOrCancelLike,
			method:"post",
			params:params
		})
	}

	//关注或者取关
	addOrCancelAttention(params){
		return http({
			url:apis.addOrCancelAttention,
			method:"post",
			params:params
		})
	}
	//获取关注列表
	getAttentionUserInfo(current){
		return http({
			url:apis.getAttentionUserInfo,
			method:"get",
			params:{current:current}
		})
	}
	//获取点赞列表
	getLikeUserInfo(current){
		return http({
			url:apis.getLikeUserInfo,
			method:"get",
			params:{current:current}
		})
	}
	//发送消息
	sendFistMsg(params){
		return http({
			url:apis.sendFistMsg,
			method:"post",
			params:params
		})
	}
	//用户详细信息
	getUserDetailInfoList(params){
		return http({
			url:apis.getUserDetailInfoList,
			method:"get",
			params:params
		})
	}
	//获取搜索列表
	getSearchInfo(params){
		return http({
			url:apis.getSearchInfo,
			method:"get",
			params:params
		})
	}
	//添加浏览量
	addBrowse(id){
		return http({
			url:apis.addBrowse,
			method:"get",
			params: {userId:id}
		})
	}

	//收集日志
	accessInfo(){
		return http({
			url:apis.accessInfo,
			method:"get"
		})
	}

}