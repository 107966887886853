import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
// import routes from './routes'

// const router = createRouter({
//   history: createWebHashHistory(),
//   routes
// });

// export default router

const router =  createRouter({
  history: createWebHashHistory(),
  routes : [
    {
      path:'/home',
      name:'Home',
      component:() => import('../views/Home.vue'),
      meta:{isAuth:true,title:'首页',keepAlive:false},
    },
    {
      path:'/header',
      name:'Header',
      component:() =>import('../components/head/Header.vue'),
      meta:{isAuth:true,title:'头部',keepAlive:false},
    },
    {
      path:'/footer',
      name:'Footer',
      component:() =>import('../components/foot/Footer.vue'),
      meta:{isAuth:true,title:'底部',keepAlive:false},
    },
    {
      path:'/personalData',
      name:'PersonalData',
      component:() =>import('../views/PersonalData'),
      meta:{keepAlive:false}
    },
    {
      path:'/PersonalDetails',
      name:'PersonalDetails',
      component:() =>import('../views/PersonalDetails.vue'),
      meta:{keepAlive:false}
    },
    {
      path:'/search',
      name:'Search',
      component:() =>import('../views/Search.vue'),
      meta:{keepAlive:false}
    },
    {
      path:'/attentionUser',
      name:'AttentionUser',
      component:() =>import('../views/AttentionUser.vue'),
      meta:{keepAlive:false}
    },
    {
      path:'/likeUserInfo',
      name:'likeUserInfo',
      component:()=>import('../views/LikeUserList.vue'),
      meta:{keepAlive:false}
    },
    {
      path:'/showPage',
      name:'ShowPage',
      component:()=>import('../views/ShowPage.vue')
    },
    {
      path:'/infoList/:e.user.id',
      name:'InfoList',
      component:()=>import('../components/InfoList.vue')
    },
    {
      path:'/',
      name:'Welcome',
      // redirect:'/welcome',
      alias:'/welcome',
      component:() =>import('../views/Welcome.vue'),
      meta:{showNav:true},
    },
    {
      path:'/',
      redirect:(to=>{return{path:'/welcome'}})
    }
  ]
})

// router.beforeEach((to,from,next) =>{
//   if(to.meta.isAuth){
//     if(localStorage.removeItem('') === ''){
//
//     }
//   }
// })

export default router