export const serverUrl = "https://yqwzh.cloud/SchoolCupid/cupid"
// export const serverUrl = "http://43.139.165.166/SchoolCupid"
// export const serverUrl = "http://127.0.0.1:8080/SchoolCupid"
//
export const ftpUrl = `${serverUrl}`
// export const ftpUrlImg = `${serverUrl}/image/`
export const ftpUrlImg = `https://yqwzh.cloud/static/schoolCupid/`
// export const appLogo = `${ftpUrl}/ic_logo.png`
export const apiBase = `${serverUrl}`
//新静态路径
export const newFtpUrl = `https://yqwzh.cloud/static/schoolCupid`



export const apis = {
	getAll: `/api/user`,
	webLogin:`api/web/login`,
	webLoginSocket:`/api/web/login/checkWebToken/`,
	deleteLoginWxMiniCodeImg:`/api/web/login/deleteLoginWxMiniCodeImg`,
	checkWebToken:`/api/web/login/checkWebToken`,
	chatWebSocket:`/api/web/chat/connection/`,
	defaultListTag:`/api/label/getLabelDefaultList`,
	collegeList:`/api/collegeList/getCollegeList`,
	uploadImg:`/api/baseService/uploadFile/upload`,
	removeImg:'/api/baseService/uploadFile/remove',
	userInfo:'/api/user/userInfo',
	getUserInfo:'/api/user/getUserInfo',
	getIndexInfo:`/api/user/getIndexInfo`,
	getIndexPopularityInfo:`/api/user/getIndexPopularityInfo`,
	addOrCancelLike:'/api/like/addOrCancelLike',
	addOrCancelAttention:'/api/attention/addOrCancelAttention',
	getAttentionUserInfo:'/api/user/getAttentionUserInfo',
	getLikeUserInfo:'/api/user/getLikeUserInfo',
	sendFistMsg:'/api/userChat/sendFistMsg',
	getUserDetailInfoList:'/api/user/getUserDetailedInformation',
	getSearchInfo:'/api/user/getIndexInfo',
	addBrowse:`/api/user/addBrowse`,

	//日志相关
	accessInfo:`/api/accessInfo/accessInfo`,


}