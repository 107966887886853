<template>
  <div class="info-detail">
    <div class="info-float">
      <div class="info-float-avatar">头像</div>
      <div class="info-float-list">列表</div>
    </div>
    <div class="info-time"></div>
    <div class="info-detail-list">
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoDetailList",
  props: {
    from: Number, // 1: 自己    2: 别人
    message: String,
    timestamp: Date,
  },
  setup(){
    return{
      srcList:[
        'https://picsum.photos/id/1/100/100',
      ],
      from1: require("../../assets/home/bg-1.png"),
      from2: require("../../assets/home/bg-2.png"),
      chatItems:[
        {
          type:1,
          message:"fdaf地方撒风大撒风阿斯顿飞fd阿斯顿飞安德森发大水发大水发大水奋斗发的大撒风安德森 发大发大水发",
          from:1,
          timestamp: new Date()
        },
        {
          type:1,
          message:"fdaf地方撒风大撒aa风阿斯顿飞ffdsafsfadsfadsfjlkjsadflkosdajfl asdjlffsaf水奋斗发的大撒风安德森 发大发大水发",
          from:1,
          timestamp: new Date()
        },
        {
          type:1,
          message:"做紧d咩嘢?",
          from:2,
          timestamp: new Date()
        },
        {
          type:1,
          message:"？？？",
          from:2,
          timestamp: new Date()
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .info-detail{
    overflow: hidden;
    width: 100%;
  }
  .info-detail-list{
    /*display: flex;*/
    /*justify-content: space-between;*/
  }
  .detail-left{
    overflow: hidden;
    //float: left;
  }
  .detail-chat{
    //display: flex;
  }
  .detail-tit{
    padding: 0 20px;
  }

  //聊天区
  .info-float{
    width: 100%;
    //float: left;
    position: relative;
    .info-float-avatar{
      //position: absolute;
      width: 20%;
      float: left;
      padding: 20px 0;
      background: #7aa1d2;
    }
    .info-float-list{
      width: 80%;
      float: left;
      //position: absolute;
      padding: 20px 0;
      background: #0052d4;
    }
  }

//  聊天页面
  .record-wrapper {
    margin: 4px;
    padding: 4px;
  }
  .time {
    text-align: center;
  }
  .msg {
    display: flex;
    flex-direction: row;
    .message-wrapper {
      max-width: 220px;
      margin: 0px 10px 0px 10px;
      .message {
        margin: 8px;
        word-wrap: break-word; //英文换行
      }
    }
    .message-wrapper-left,.message-wrapper-right {
      //background-color: lightslategray;
      //border-radius: 0px 12px 12px 12px;
      position: relative;
      //padding: 5px 8px;
      word-break: break-all;
      background: #6be585;
      border: 1px solid #6be585;
      border-radius: 5px;
      max-width: 180px;
    }
    .message-wrapper-left::before{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 9px;
      left: -20px;
      border: 10px solid;
      border-color: transparent #6be585 transparent transparent;
    }
    .message-wrapper-right::before{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 9px;
      right: -20px;
      border: 10px solid;
      border-color: transparent transparent transparent #6be585;
    }
    .img {
      flex: auto;
      height: 36px;
      width: 36px;
      border-radius: 8px;
    }
  }
  .msg-right {
    flex-direction: row-reverse;
  }
  .msg-left {
    flex-direction: row;
  }
  .img-wrapper img{
    height: 50px;
    width: 50px;
    border-radius: 50px;
  }
</style>