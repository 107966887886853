import axios from "axios"
import {apiBase} from "@/global/config";
// import {apiBase} from "../global/config"

const http = axios.create({
	// baseURL:"/api"
	baseURL:apiBase
})

//请求拦截器
http.interceptors.request.use((config)=>{
	config.headers = config.headers || {};
	if (localStorage.getItem("WZH-WebToken")) {
		let webHeaders = "X-Access-Token"
		config.headers[webHeaders]= localStorage.getItem("WZH-WebToken") || "";
	}
	if (sessionStorage.getItem("useDev") != null){
		let webHeaders = "X-Web-Access-useDev"
		config.headers[webHeaders]= sessionStorage.getItem("useDev") || "";
	}
	if (sessionStorage.getItem("useOs") != null){
		let webHeaders = "X-Web-Access-useOs"
		config.headers[webHeaders]= sessionStorage.getItem("useOs") || "";
	}
	return config;
})
//返回拦截器
http.interceptors.response.use((res)=>{
	if (res.code == 403){
		this.$message("未登录！")
		return
	}
	//请求成功
	return res.data;
},(error)=>{
	//请求出错
	new Error(error)
});

export default http;