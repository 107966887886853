<template>
    <div class="foot">
        <div class="foot-list">
<!--            <div class="list-li">-->
<!--                <ul>-->
<!--                    <li>-->
<!--                      关于我们-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <ul>-->
<!--                    <li>-->
<!--                      联系我们-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <ul>-->
<!--                    <li>-->
<!--                      注册登录-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <ul>-->
<!--                    <li>-->
<!--                      联系方式-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
            <div class="foot-cont">
                <div>茫茫人海中，能够相遇是一种缘分</div>
            </div>
            <div class="foot-btm">
                <p>滇ICP备2023000135号 pro六人小组 校缘网</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name:'Footer',
    }
</script>

<style lang="less" scoped>
    .foot{
        overflow: hidden;
    }
    .foot-top{
        background: url('@/assets/home/foot-bg.jpg') no-repeat;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 20px 0;
        .top-left{
            display: flex;
            align-items: center;
            .left-img{
                width: 200px;
                height: 200px;
                img{
                    width: 200px;
                    height: 200px;
                }
            }
            .left-tit{
                color: #fff;
                padding-top: 20px;
                font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
                p{
                    margin: 10px 0;
                }
            }
        }
        .top-right{
            color: #333;
            padding-top: 10px;
            h1{
                font-family: Arial, Helvetica, sans-serif;
            }
            .right-tit{
                padding: 4px 0;
                font-size: 16px;
                background-color: #fff;
                border: 1px solid #ccc;
                border-radius: 4px;
                margin: 4px 0 8px 0;
                color: #ef6f6f;
            }
            a{
                padding: 4px 0;
                text-decoration: underline;
                color: #333;
            }
            a:hover{
                color: #fff;
                cursor: pointer;
            }
        }
    }
    .foot-list{
        overflow: hidden;
      background: #434343;
        padding: 10px 0 0 0;
        .list-li{
            display: flex;
            justify-content: space-around;
            width: 90%;
            margin: 0 auto;
          color: #fff;
            li{
                list-style: none;
                padding: 2px 0;
            }
        }
    }
    .foot-cont{
      text-align: center;
        color: #c0c0c0;
        padding: 4px 0;
        width: 90%;
        margin: 0 auto;
        //border-top: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0;
    }
    .foot-btm{
        padding: 10px 0;
        text-align: center;
        color: #c0c0c0;
    }
</style>