import Vue from 'vue'
import Vuex from 'vuex'

export default new Vuex.Store({
	state:{
		// storage:[]
	},
	getters:{
		// storage:state => state.storage
	},
	mutations:{
		// webToken
	},
	actions:{
		
	},
})