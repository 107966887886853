<template>
  <div class="nav-head">
    <div class="nav-top">
      <div class="nav-left">
        <div class="nav_logo">
          <div>
            <img src="../../assets/Logo.jpg" alt="" class="nav_img1">
            <img src="../../assets/Title.png" alt="" class="nav_img2">
          </div>
          <div class="nav_font">
            SCHOOL CUPID
          </div>
        </div>
<!--        <div class="nav-logo">-->
<!--          <div class="nav-logo-tit">-->
<!--            <h4>XY校缘网</h4>-->
<!--          </div>-->
<!--          <div class="nav-logo-xtit">-->
<!--            XIAOYUAN-->
<!--          </div>-->
<!--        </div>-->
        <div class="nav-title">
          <li>
            <router-link to="/home">首页</router-link>
          </li>
          <li>
            <router-link to="/search">搜索</router-link>
          </li>
          <li>
              <router-link to="/likeUserInfo">喜欢</router-link>
          </li>
          <li>
            <router-link to="/attentionUser">关注</router-link>
          </li>
          <li>
            <a @click="activate('right')" class="head-info">
              <n-drawer
                v-model:show="active"
                :width="defaultWidth"
                :placement="placement"
                resizable
                :on-after-leave="onAfterLeave"
              >
                <n-drawer-content>
                  <div class="chatTitleView" @click.stop="onClickMsgTitle">
                    {{ showInfoList == 0 ? "消息列表" : "退出会话" }}
                  </div>
                  <!--                          <InfoList></InfoList>-->
                  <div class="info-draw">
                    <div class="info-list info-detail-list">
                      <!-- 用户消息-->
                      <div
                        v-if="showInfoList == 0"
                        v-for="(chatInfoItem,chatInfoIndex) in chatInfoList"
                        @click.stop="onClickAvatar(1,chatInfoItem,chatInfoIndex)"
                        class="info-list-infoList"
                      >
                        <div class="info-left">
                          <div class="info-avatar">
                            <el-avatar
                              :size="50"
                              :src="activeUrl+chatInfoItem.head_url"
                            />
                            <div :class="chatInfoItem.isOnline ? 'info-avatar-state':'info-avatar-state-leave'"></div>
                          </div>
                          <div class="info-tit">
                            <p class="info-user">{{chatInfoItem.name}}</p>
                            <p class="info-state" v-if="chatInfoItem.isOnline">在线</p>
                            <p class="info-state" v-if="!chatInfoItem.isOnline">离线</p>
                          </div>
                          <div class="info-chatBox chatBox-left">
                              {{chatInfoItem.content}}
                          </div>
                        </div>
                        <div class="info-right">
                          <n-space
                            :size="24"
                            align="center"
                            item-style="display: flex;"
                          >
                            <n-badge :value="chatInfoItem.unread_read_num"/>
                          </n-space>
                        </div>
                      </div>
                      <!-- 聊天列表区域-->
                      <div
                        v-if="showInfoList == 1"
                        class="info-list-infoDetailList"
                      >
                        <div class="info-user-float" @click="readMsg">
                          <!-- 用户头像-->
                          <div class="info-user-left">
                            <div @click.stop="onClickAvatar(1,chatInfo,chatInfoIndex)" class="user-left-avatar" v-for="(chatInfo,chatInfoIndex) in chatInfoList">
<!--                              <el-avatar :size="55" :src="chatInfo.head_url"  />-->
                                <el-avatar :size="50" :src="activeUrl+chatInfo.head_url" />
                                <div :class="chatInfo.isOnline ? 'info-avatar-state':'info-avatar-state-leave'"></div>
                                <div v-if="chatInfo.unread_read_num>0" class="unreadReadNumView"></div>
<!--                                <div class="unreadReadNumView">{{chatInfo.unread_read_num}}</div>-->
                            </div>
                          </div>

                          <div class="info-user-right" id="scrollMsgView" :onscroll="scrollMsgView">
<!--                          <div class="info-user-right" id="scrollMsgView" >-->
                            <div class="user-right-list">
                              <div class="record-wrapper" v-for="(chatItem,chatIndex) in chatInfo">
                                <div v-if="chatTime(chatItem.sendDate,chatIndex)" class="time">{{chatTime(chatItem.sendDate,chatIndex)}}</div>
                                <div :class="msgStyleCss(chatItem.receiveUserId)">
                                  <div class="img-wrapper">
                                    <img :src="chatItem.receiveUserId == this.userId ? activeUrl+sendUserHeadUrl:avatar" />
                                  </div>
                                  <div :class="messageWrapper(chatItem.receiveUserId)">
                                    <div class="message">{{chatItem.content}}</div>
                                  </div>
                                </div>
<!--                                <div class="msg msg-left">-->
<!--                                  <div class="img-wrapper">-->
<!--                                    <img-->
<!--                                      src="../../assets/home/bg-1.png"-->
<!--                                      alt=""-->
<!--                                    />-->
<!--                                  </div>-->
<!--                                  <div-->
<!--                                    class="message-wrapper message-wrapper-left"-->
<!--                                  >-->
<!--                                    <div class="message">uwhdfbdsj</div>-->
<!--                                  </div>-->
<!--                                </div>-->
                                  <div class="readStateView" v-if="chatInfo.length -1 ==chatIndex && chatItem.receiveUserId != this.userId">
                                      <div v-if="chatItem.isRead==1">已读</div>
                                      <div v-else>未读</div>
                                  </div>
                              </div>

                              <!--聊天输入框-->
                              <div class="info-list-input">
                                <div class="detail-input">
                                  <!-- <el-input v-model="input" placeholder="Please input" />-->
                                  <textarea
                                    autofocus
                                    name="text"
                                    cols="40"
                                    rows="3"
                                    wrap="hard"
                                    v-model="textareaValue"
                                    @keydown.enter="enterBtn"
                                    placeholder="请输入文字"
                                  ></textarea>
                                </div>
                                <div class="detail-btn">
                                  <el-button @click.stop="sendMgs" class="btn info-send">发送</el-button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </n-drawer-content>
              </n-drawer>
              <el-badge is-dot class="item">
                <el-icon color="#fff" :size="30">
                  <ChatDotSquare> </ChatDotSquare>
                </el-icon>
              </el-badge>
            </a>
          </li>
        </div>
      </div>
      <div class="nav-right">
        <div class="nav-info modal-box-mask">
          <a @click="showModal = true" v-show="showLogin == true">登录</a>
          <n-modal
            v-model:show="showModal"
            class="custom-card modal-mask"
            preset="card"
            :style="bodyStyle"
            :on-after-enter="ModelShow"
            :on-after-leave="closedModel"
            :bordered="false"
            :segmented="segmented"
            style="width: 360px !important;"
          >
            <template #header-extra class="head-logo">
              <span style="font-size: 12px">请登录~</span>
            </template>
            <n-card size="small" class="card-cont" >
              <n-tabs
                type="line"
                animated
                default-value="oasis"
                justify-content="space-evenly"
                size="small"
              >
                <n-tab-pane name="oasis" tab="扫码登录">
                  <el-image
                            :src="imgUrl"
                            fit="cover"
                            loading="lazy"
                            class="wx-ewm"/>
<!--                  <img :src="imgUrl" alt="" class="wx-ewm" />-->
                  <p class="wx-xtit">扫一扫,快速登录</p>
                </n-tab-pane>
              </n-tabs>
            </n-card>
          </n-modal>
        </div>
        <div class="nav-nick">{{ nickname }}</div>
        <div class="nav-user">
          <n-dropdown :options="options" :on-select="onSelect">
            <img :src="avatar" alt="" />
          </n-dropdown>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref, watch } from "vue";
import App from "@/App.vue";
import { apis, apiBase, ftpUrl ,newFtpUrl} from "@/global/config";
import InfoList from "../information/InfoList.vue";
import { ElLoading } from 'element-plus'
import {Delete} from "@element-plus/icons-vue";
let _this = this;
let WElLoading;
/*
* requestData.type
* 0:消息列表
* 1:消息内容
* 2:收到消息
* 3:用户上线下线通知
* 4:已读消息
* */
export default {
  name: "Header",
  components: {Delete, InfoList },
  methods: {
    //已读消息
    readMsg(){
        if (this.isNewMsg){
            let requestData = {
                type:4,
                sendUserId:this.sendUserId,
                receiveUserId:this.userId,
            }
            this.chatWebSocketSend(JSON.stringify(requestData))
            this.isNewMsg = false
        }
    },
    //发送消息
    sendMgs(){
        if (this.textareaValue =="" || this.textareaValue == null){
            this.$message("请输入要发送的消息！")
            return;
        }
        let requestData = {
            type:2,
            sendUserId:this.userId,
            receiveUserId:this.sendUserId,
            contentType:0,
            content:this.textareaValue,
            imgUrl:null,
        }
        this.chatWebSocketSend(JSON.stringify(requestData))
        this.textareaValue=null;
        // console.log(requestData)
        // this.chatInfo.push(requestData)
    },
    //回车发送消息
    enterBtn(e){
        if (!e.metaKey){
            e.preventDefault()
            this.sendMgs()
        }else {
            this.textareaValue = this.textareaValue+'\n';
        }
        // console.log(e)
    },
    //查看更多聊天记录
    scrollMsgView(e){
        if (e.target.scrollTop == 0){
            if (!this.nextPage){
                this.$message("没有更多聊天记录了！");
                return;
            }
            this.pageCurrent++;
            console.log(this.pageCurrent)
            let requestData = {
                type:1,
                sendUserId:this.sendUserId,
                current:this.pageCurrent,
                size:20,
            }
            this.chatWebSocketSend(JSON.stringify(requestData))

        }

    },
      //判断时间是否大于三分钟
    chatTime(sendTime,chatIndex){
        if (chatIndex == 0){
            return new Date(sendTime)
        }
        let sendT = sendTime/1000;
        let upSendT = this.chatInfo[chatIndex-1].sendDate/1000
        if(sendT - upSendT >3*60){
            return new Date(sendTime)
        }
        return false;
    },
    msgStyleCss(userId){
        if (userId==this.userId){
            return "msg msg-left"
        }else {
            return "msg msg-right"
        }
    },
    messageWrapper(userId){
        if (userId==this.userId){
            return "message-wrapper message-wrapper-left"
        }else {
            return "message-wrapper message-wrapper-right"
        }
    },
    chatWebSocket(){
      // // 建立连接
      // // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      // var url =
      //   apiBase + apis.chatWebSocket + localStorage.getItem("WZH-WebToken");
      // url = url.replace("https://", "wss://").replace("http://", "ws://");
      // this.chatWebSocket = new WebSocket(url);
      this.chatWebSocket.onopen = this.chatWebSocketOnopen;
      // this.chatWebSocket.send = this.chatWebSocketSend;
      this.chatWebSocket.onerror = this.chatWebSocketOnerror;
      this.chatWebSocket.onmessage = this.chatWebSocketOnmessage;
      this.chatWebSocket.onclose = this.chatWebSocketClose;
    },
    // 连接成功后调用
    chatWebSocketOnopen: function () {
      console.log("chatWebSocket连接成功");
    },
    // 发生错误时调用
    chatWebSocketOnerror: function () {
      console.log("chatWebSocket连接发生错误");
    },
    // 给后端发消息时调用
    chatWebSocketSend: function (e){
      this.chatWebSocket.send(e)
        // this.textareaValue=null;
      console.log("chatWebSocket发送");
    },
    // 接收后端消息
    // vue 客户端根据返回的cmd类型处理不同的业务响应
    chatWebSocketOnmessage: function (e) {
      let data = eval("(" + e.data + ")");
      //type=0聊天列表
      if(data.type == 0){
        this.chatInfoList = data.data
      }
      //type == 1聊天内容
      if (data.type == 1){
          // this.isNewMsg=true
          if (this.pageCurrent>0){
              let list = data.data.chatList.concat(this.chatInfo)
              this.chatInfo = list
              WElLoading = ElLoading.service({target:"#scrollMsgView"})
          }
          else {
              this.chatInfo = data.data.chatList
              let smv = document.getElementById("scrollMsgView");
              WElLoading = ElLoading.service({target:"#scrollMsgView"})
              setTimeout(function (){
                  smv.scrollTo(0,1000)
              },200)
          }
          WElLoading.close()
          this.nextPage=data.data.nextPage
      }
      //收到的消息
      if(data.type==2){
          this.isNewMsg = true
          let smv = document.getElementById("scrollMsgView");
          if (this.sendUserId == data.data.user.id){
              this.chatInfo.push(data.data.chatList);
              this.sendUser = data.data.user;
          }
          if (this.userId != data.data.user.id){
              this.$notify({
                  title:data.data.user.name,
                  type:'info',
                  message:data.data.chatList.content,
              })
          }else {
              this.chatInfo.push(data.data.chatList);
              this.sendUser = data.data.user;
          }
          if (this.showInfoList == 1){
            setTimeout(function (){
              smv.scrollTo(0,smv.scrollTop+1000)
            },100)
          }

      }
      //用户上线下线通知
      if (data.type == 3){
          for (let chatInfoListElement of this.chatInfoList) {
            if (chatInfoListElement.send_user_id == data.data.userId){
                chatInfoListElement.isOnline = data.data.isOnline;
            }
          }
          // console.log(data)
      }
      //已读状态更新
      if(data.type == 4){
          if (this.sendUserId == data.data.receiveUserId){
              this.chatInfo[this.chatInfo.length -1] = data.data
          }

      }
      // 发送新消息并进入聊天框
      if (data.type == 5){
          if (this.sendNewMgsUserId !== -1) {
              this.chatInfoList = data.data
              for (let i = 0; i < data.data.length; i++) {
                  if (data.data[i].send_user_id == this.sendNewMgsUserId){
                      this.activate('right')
                      this.pageCurrent = 0;
                      this.showInfoList = 1;
                      this.sendUserHeadUrl = data.data[i].head_url;
                      this.sendUserId = data.data[i].send_user_id;
                      this.nextPage = true;
                      this.unreadReadNum = data.data[i].unread_read_num;
                      this.chatInfoListIndex = i;
                      // this.sendUnreadReadNum = item.sendUnreadReadNum;
                      let requestData = {
                          type:1,
                          sendUserId:data.data[i].send_user_id,
                          current:this.pageCurrent,
                          size:10,
                      }
                      this.chatWebSocketSend(JSON.stringify(requestData))
                      break;
                      // this.readMsg()
                  }
              }
          }
      }
      console.log("data:", data);
    },
    // 关闭连接时调用
    chatWebSocketClose: function (e) {
      console.log("chatWebSocket connection closed (" + e.code + ")");
    },

    //点击头像进入聊天
    onClickAvatar(e,item,index) {
      this.pageCurrent = 0;
      this.showInfoList = e;
      this.sendUserHeadUrl = item.head_url;
      this.sendUserId = item.send_user_id;
      this.nextPage = true;
      this.unreadReadNum = item.unread_read_num;
      this.chatInfoListIndex = index;
      // this.sendUnreadReadNum = item.sendUnreadReadNum;
      let requestData = {
          type:1,
          sendUserId:item.send_user_id,
          current:this.pageCurrent,
          size:10,
      }
      this.chatWebSocketSend(JSON.stringify(requestData))
      this.readMsg()
    },
    // 外部组件调用进入聊天页面
    fistSendMsg(userId){
        this.sendNewMgsUserId = userId;
        // let requestData = {
        //     type:5,
        // }
        // alert(this.chatWebSocket)
        // this.active = true
        // console.log(this.active)
        // console.log(this.placement)
        // console.log(this)
        // this.activate('right')
        // this.showInfoList = 1;
        // console.log(this.chatWebSocketSend)
// this.chatWebSocketSend(JSON.stringify(requestData))
//         this.chatWebSocketSend(JSON.stringify(requestData))
    },
    onAfterLeave() {
      this.showInfoList = 0;
    },
    onClickMsgTitle() {
      if (this.showInfoList == 1) {
        this.showInfoList = 0;
      }
    },
    onSelect(key, option) {
      console.log(key);
      console.log(option);
      if (key == "profile") {
        this.$router.push({ path: "/PersonalDetails" });
      } else if (key == "editProfile") {
        this.$router.push({ path: "/personalData" });
      } else {
        this.$router.push({ path: "/welcome" });
        this.$parent.showFooter = false
        this.$parent.showHeader = false
        // this.$parent.showContent = false
        localStorage.removeItem("WZH-WebToken");

        // console.log(localStorage.removeItem("WZH-WebToken"))
        this.avatar =
          "https://yqwzh.cloud/static/SchoolCupid/defaultImg/default_headImg.png";
        this.nickname = "";
        this.showLogin = true;
        // this.chatWebSocket.onclose(555)
      }
    },
    ModelShow() {
      this.isClosedWebSocket = false;
      this.$requestCenter.webLogin().then((res) => {
        this.imgUrl = res.data.wxMiniCodePath;
        if (res.code == 20041) {
          this.webToken = res.data.webToken;
          this.initWebSocket();
        }
      });
    },
    closedModel() {
      let result = JSON.parse(localStorage.getItem("storage"));
      console.log(result);

      if (this.websock != null) {
        this.websock.close();
      } else {
        this.isClosedWebSocket = true;
      }
      this.$requestCenter.deleteLoginWxMiniCodeImg({ imgName: this.imgUrl });
    },
    initWebSocket: function () {
      // 建立连接
      // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
      // var userId = store.getters.userInfo.id;
      if (this.webToken == -1) {
        return;
      }
      if (this.isClosedWebSocket) {
        return;
      }
      var url = apiBase + apis.webLoginSocket + this.webToken;
      url = url.replace("https://", "wss://").replace("http://", "ws://");
      this.websock = new WebSocket(url);
      this.websock.onopen = this.websocketonopen;
      // this.websock.send = this.websocketsend;
      this.websock.onerror = this.websocketonerror;
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onclose = this.websocketclose;
    },
    // 连接成功后调用
    websocketonopen: function () {
      console.log("WebSocket连接成功");
    },
    // 发生错误时调用
    websocketonerror: function () {
      console.log("WebSocket连接发生错误");
    },
    // 给后端发消息时调用
    websocketsend: function (e) {
      this.websock.send(e);
        console.log(e)
      console.log("WebSocket发送数据");
    },
    // 接收后端消息
    // vue 客户端根据返回的cmd类型处理不同的业务响应
    websocketonmessage: function (e) {
      var data = eval("(" + e.data + ")");
      //处理订阅信息
      console.log("缓存");
      // localStorage.setItem("WZH-WebToken","888888888888")
      localStorage.setItem("WZH-WebToken", data.webToken);
      console.log(localStorage.getItem("WZH-WebToken"));
      // console.log(data)

      //用户头像和昵称
      console.log("用户信息", data);
      // this.avatar = ftpUrl + data.user.headUrl;
      this.avatar = newFtpUrl + data.user.headUrl;
      this.nickname = data.user.name;
      this.userId = data.user.id;
      if (data.loginType == 1 || data.loginType == 2) {
        this.$router.push({ path: "https://yqwzh.ink/SchoolCupid/" });
        // location.href='https://yqwzh.ink/SchoolCupid/'
      }
      this.showModal = false;
      this.showLogin = false;
    },
    // 关闭连接时调用
    websocketclose: function (e) {
      console.log("connection closed (" + e.code + ")");
      // console.log(this)
      this.chatWebSocketOnopen();
    },
  },
    mounted() {
      _this = this;
    },
    created() {
    //路由重定向
    //   if(this.$router.patch !== "/"){
    //     this.$router.replace("/welcome")
    //   }
    this.$requestCenter.checkWebToken().then((e) => {
      if (e.data != null) {
        this.avatar = newFtpUrl + e.data.headUrl;
        this.nickname = e.data.name;
        this.userId = e.data.id;
        this.showLogin = false;
        // this.chatWebSocket.onclose(6666);

          // 建立连接
          // WebSocket与普通的请求所用协议有所不同，ws等同于http，wss等同于https
          var url =
              apiBase + apis.chatWebSocket + localStorage.getItem("WZH-WebToken");
          url = url.replace("https://", "wss://").replace("http://", "ws://");
          this.chatWebSocket = new WebSocket(url);
          console.log(this.chatWebSocket)
          this.chatWebSocket.onopen = this.chatWebSocketOnopen;
          // this.chatWebSocket.send = this.chatWebSocketSend;
          this.chatWebSocket.onerror = this.chatWebSocketOnerror;
          this.chatWebSocket.onmessage = this.chatWebSocketOnmessage;
          this.chatWebSocket.onclose = this.chatWebSocketClose;

      }
    });
  },
  setup() {
    const showInfoList = ref(0);
    watch(showInfoList, (newData) => {
      if (newData == 1) {
        _this.defaultWidth = 800
      } else {
        _this.defaultWidth = 502
      }
      console.log(newData);
      // alert("aaa")
    });
    const activeRef = ref(false);
    const placementRef = ref("right");
    const activate = (place) => {
      activeRef.value = true;
      placementRef.value = place;
    };
    return {
      options: [
        {
          label: "个人资料",
          key: "profile",
          // icon: renderIcon(UserIcon)
        },
        {
          label: "编辑资料",
          key: "editProfile",
          // icon: renderIcon(EditIcon)
        },
        {
          label: "退出登录",
          key: "logout",
          // icon: renderIcon(LogoutIcon)
        },
      ],
      bodyStyle: {
        width: "600px",
      },
      showModal: ref(false),
      imgUrl: ref(""),
      webToken: ref(-1),
      isClosedWebSocket: ref(false),
      avatar: ref(
        "https://yqwzh.ink/SchoolCupid/defaultImg/default_headImg.png"
      ),
      nickname: ref(""),
      showLogin: ref(true),
      // checkWebToken
      active: activeRef,
      placement: placementRef,
      activate,
      showInfoList,
      // showInfoList:ref(0),
      input: ref(""),
      defaultWidth:ref(502),
      userId:ref(),
      activeUrl:ftpUrl,
      //聊天列表
      chatInfoList:ref([]),
      //聊天内容
      chatInfo:ref([]),
      //聊天对象头像
      sendUserHeadUrl:ref(),
      pageCurrent:ref(0),
      nextPage:ref(true),
      //输入框消息
      textareaValue:ref(),
      //发送消息用户消息
      sendUser:ref(),
      //未读消息数
      unreadReadNum:ref(0),
      //当前消息列表下标
      chatInfoListIndex:ref(0),
      // sendUnreadReadNum:ref(0)
      //是否新消息
      isNewMsg:ref(true),
      sendNewMgsUserId:ref(-1),
      chatWebSocket:ref(),
    };
  },
};

</script>

<style lang="less" scoped>
//@nav-color: #bc4258;
//@import "./src/assets/style/base.css";
@import "./src/assets/style/base.less";
li {
  list-style: none;
}
.nav-head {
  position: fixed;
  overflow: hidden;
  padding: 10px 0;
  //background: #f0a1a8;
  //background: rgba(186, 204, 217, 0.31);
  background: @nav-color;
  //box-shadow: 1px 2px 10px #c2c2c2;
  //box-shadow: 1px 1px 3px #bc4258;
  width: 100%;
  height: 74px;
  z-index: 99;
}
.nav-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: auto;
}
.nav_logo{
  display: flex;
  flex-direction: column;

  .nav_img1{
    width: 48px;
    height: 30px;
  }

  .nav_img2{
    width: 40px;
    height: 18px;
    top: 4px;
    left: 2px;
    position: relative;
  }
}
.nav_font{
  font-size: 10px;
  color: #000;
  font-weight: bold;
}

.nav-left {
  display: inline-flex;
  align-items: center;
  .nav-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: @nav-title;
    //background-image: -webkit-linear-gradient(0deg,#40e0d0 20%,#ff8c00,#ff0080 100%);
    //background-image: -webkit-linear-gradient(90deg, #c4e0e5 0%,#4CA1AF 100%);
    -webkit-background-clip: text;
    color: transparent;
    //img{
    //  width: 60px;
    //  height: 60px;
    //  object-fit: cover;
    //  background: transparent;
    //}
    .nav-logo-tit{
      h4{
        font-size: 22px;
        font-weight: bold;
        margin: 0;
        padding: 0 6px;
      }
    }
    .nav-logo-xtit{
      font-size: 12px;
      letter-spacing: 4px;
    }
  }
  .nav-title {
    margin-left: 20px;
    display: flex;
    align-items: center;
    a {
      text-decoration: none;
      color: @nav-font-color;
      font-size: @nav-font;
      display: flex;
      font-weight: 400;
    }
    a:hover {
      color: @nav-hover;
      cursor: pointer;
      font-size: @nav-font;
    }
    li {
      padding: 0 20px;
      .head-info {
        position: relative;
        top: 4px;
      }
    }
  }
}
.nav-right {
  display: flex;
  align-items: center;
  color: @nav-font-color;
  .nav-info {
    display: flex;
    align-items: center;
    margin: 0 20px;
    a {
      cursor: pointer;
      font-size: @nav-font-color;
    }
    a:hover {
      color: @nav-hover;
      font-size: @nav-font;
    }
  }
  .nav-user {
    n-dropdown:hover {
      border: none;
    }
    img {
      width: 50px;
      height: 50px;
      border-radius: 50px;
      border: 1px solid #fff;
    }
  }
  .nav-nick {
    padding: 0 20px 0 0;
  }
}
.head-logo {
  width: 30px;
  height: 30px;
  .img {
    width: 15px;
    height: 15px;
  }
}
// tabs
.card-tabs .n-tabs-nav--bar-type {
  padding-left: 4px;
}
.card-cont {
  text-align: center;
  .login {
    text-align: center;
  }
  .wx-ewm {
    margin-top: 10px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .wx-xtit {
    padding: 10px 0;
    font-size: 12px;
    color: #bababa;
  }
}
//聊天区域样式
.info-draw {
  overflow: hidden;
}
.info-avatar-state {
  position: relative;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  background: #6be585;
  margin-top: -20px;
  margin-left: 48px;
  //top: 110px;
  //left: 60px;
  border: 1px solid #fff;
}
.unreadReadNumView{
  position: relative;
  margin-top: -53px;
  margin-left: 48px;
  background-color: red;
  width: 10px;
  height: 10px;
  font-size: 1px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid #fff;
  //text-align: center;
  display: flex;
}
.unreadReadNumViewNone{
  position: relative;
  margin-top: -53px;
  margin-left: 48px;
  background-color: transparent;
  width: 10px;
  height: 10px;
  font-size: 1px;
  border-radius: 50%;
  color: #fff;
  border: 1px solid transparent;
  //text-align: center;
  display: flex;
}
.readStateView{
  width: 610px;
  display: flex;
  justify-content: flex-end;
  font-size: 1px;
  opacity: 0.8;
}
.info-avatar-state-leave {
  position: relative;
  border-radius: 50px;
  width: 15px;
  height: 15px;
  background: red;
  margin-top: -20px;
  margin-left: 48px;
  //top: 110px;
  //left: 60px;
  border: 1px solid #fff;
}
.info-list-infoList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
  border-bottom: 1px solid #ededed;
  .info-left {
    display: flex;
    align-items: center;
    .info-avatar {
      .info-avatar-state {
        position: relative;
        border-radius: 50px;
        width: 15px;
        height: 15px;
        background: #6be585;
        margin-top: -20px;
        margin-left: 35px;
        //top: 110px;
        //left: 60px;
        border: 1px solid #fff;
      }
      .info-avatar-state-leave {
        position: relative;
        border-radius: 50px;
        width: 15px;
        height: 15px;
        background: red;
        margin-top: -20px;
        margin-left: 35px;
        //top: 110px;
        //left: 60px;
        border: 1px solid #fff;
      }
    }
  }
  .info-tit {
    //margin-top: 20px;
    padding: 6px 10px;
    height: 55px;
  }
  .info-user {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    max-width: 100px;
  }
  .info-state {
    font-size: 10px;
  }
  .info-chatBox {
    position: relative;
    margin: 12px 0;
    padding: 5px 8px;
    word-break: break-all;
    //background: #6be585;
    //border: 1px solid #6be585;
    border-radius: 5px;
    max-width: 250px;
    //width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .chatBox-left::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: 9px;
    left: -20px;
    border: 10px solid;
    border-color: transparent #6be585 transparent transparent;
  }
}

//聊天列表区域
.info-list-infoDetailList {
  overflow: hidden;
  .info-user-float {
    width: 100%;
    //background: #7aa1d2;
    .info-user-left {
      padding-top: 20px;
      box-sizing: border-box;
      width: 10%;
      height: 100%;
      position: absolute;
      left: 0;
      overflow: auto;
      .user-left-avatar {
        margin-top: 10px;
        text-align: center;
        height: 50px;
        border-right: 1px solid #ededed;
      }
      .user-left-avatar:first-child{
        margin-top: 0;
      }
    }
    .info-user-left::-webkit-scrollbar {
      display: none;
    }
    .info-user-right {
      width: 85%;
      height: 70%;
      position: absolute;
      right: 0;
      overflow: auto;
    }
    .info-user-right::-webkit-scrollbar {
      display: none;
    }
    .user-right-list {
      width: 100%;
      right: 0;
      //  聊天页面
      .record-wrapper {
        margin: 4px;
        padding: 4px;
      }
      .time {
        text-align: center;
        font-size: 3px;
        color: #7c7a7a;
      }
      .msg {
        display: flex;
        flex-direction: row;
        .message-wrapper {
          max-width: 220px;
          margin: 0px 10px 0px 10px;
          .message {
            margin: 8px;
            word-wrap: break-word; //英文换行
          }
        }
        .message-wrapper-left,
        .message-wrapper-right {
          position: relative;
          //padding: 5px 8px;
          word-break: break-all;
          //background: @chat-color;
          border: 1px solid @chat-color;
          background: #fff;
          color: #333;
          //color: @chat-font-color;
          font-size: @chat-font-size;
          border-radius: 5px;
          letter-spacing: 1px;
          max-width: 180px;
          height: 40px;
          top: 8px;
        }
        .message-wrapper-left{
          left: 6px;
        }
        .message-wrapper-right{
          right: 6px;
        }
        .message-wrapper-left::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 10px;
          left: -20px;
          border: 10px solid;
          border-color: transparent @chat-color transparent transparent;
        }
        .message-wrapper-right::before {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 9px;
          right: -20px;
          border: 10px solid;
          border-color: transparent transparent transparent @chat-color;
        }
        .img {
          flex: auto;
          height: 36px;
          width: 36px;
          border-radius: 8px;
        }
      }
      .msg-right {
        flex-direction: row-reverse;
        margin: 10px 0;
      }
      .msg-left {
        flex-direction: row;
        margin: 10px 0;
      }
      .img-wrapper img {
        height: 50px;
        width: 50px;
        border-radius: 50px;
      }
    }
  }
  .info-list-input {
    position: fixed;
    bottom: 10px;
    padding: 10px;
    display: flex;
    justify-content: space-around;
    align-items: end;
    .detail-input {
      width: 80%;
      textarea {
        padding: 10px;
        border: 1px solid #b9bbbe;
        outline: #b9bbbe;
        border-radius: 4px;
        //text-indent: 24px;//文字首行缩进
      }
    }
    .detail-btn {
      margin: 0 10px;
      outline: none;
      .info-send{
        background: @chat-send;
        color: #fff;
      }
    }
  }
}
.chatTitleView {
  width: 100%;
  height: 30px;
  border-bottom: rgb(230, 230, 230) solid 1px;
}

</style>
