<template>
  <div class="info-draw">
    <div class="info-list info-detail-list">
      <!-- 用户消息-->
      <div class="info-list-infoList">
        <div class="info-left">
          <div class="info-avatar">
<!--            <el-badge is-dot class="item info-avatar-state">-->
<!--              <el-avatar :size="50" src="https://yqwzh.ink/SchoolCupid/defaultImg/default_headImg.png" />-->
<!--            </el-badge>-->

            <div class="info-avatar-state">
              <el-avatar :size="50" src="https://yqwzh.ink/SchoolCupid/defaultImg/default_headImg.png" />
            </div>
          </div>
          <div class="info-tit">
            <p class="info-user">用户名</p>
            <p class="info-state">在线</p>
          </div>
          <div class="info-chatBox chatBox-left">
            气泡框在这里显示消息提示
          </div>
        </div>
        <div class="info-right">
          <n-space :size="24" align="center" item-style="display: flex;">
            <n-badge :value="value" :max="15" />
          </n-space>
        </div>
      </div>
      <!-- 聊天列表区域-->
      <div class="info-list-infoDetailList">
        <div class="info-user-float">
          <!-- 用户头像-->
          <div class="info-user-left">
            <div class="user-left-avatar" v-for="item in 15">
              <el-avatar :size="55" :src="item" :key="item"/>
<!--              <el-avatar :size="55" src="https://yqwzh.ink/SchoolCupid/defaultImg/default_headImg.png" />-->
            </div>
          </div>
          <div class="info-user-right">
            <div class="user-right-list">
              <div class="record-wrapper">
                <div class="time"></div>
                <div class="msg msg-right">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-2.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-right">
                    <div class="message">26324237</div>
                  </div>
                </div>
                <div class="msg msg-left">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-1.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-left">
                    <div class="message">uwhdfbdsj</div>
                  </div>
                </div>
              </div>
              <div class="record-wrapper">
                <div class="time"></div>
                <div class="msg msg-right">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-2.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-right">
                    <div class="message">26324237</div>
                  </div>
                </div>
                <div class="msg msg-left">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-1.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-left">
                    <div class="message">uwhdfbdsj</div>
                  </div>
                </div>
              </div>
              <div class="record-wrapper">
                <div class="time"></div>
                <div class="msg msg-right">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-2.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-right">
                    <div class="message">26324237</div>
                  </div>
                </div>
                <div class="msg msg-left">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-1.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-left">
                    <div class="message">uwhdfbdsj</div>
                  </div>
                </div>
              </div>
              <div class="record-wrapper">
                <div class="time"></div>
                <div class="msg msg-right">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-2.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-right">
                    <div class="message">26324237</div>
                  </div>
                </div>
                <div class="msg msg-left">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-1.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-left">
                    <div class="message">uwhdfbdsj</div>
                  </div>
                </div>
              </div>
              <div class="record-wrapper">
                <div class="time"></div>
                <div class="msg msg-right">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-2.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-right">
                    <div class="message">26324237</div>
                  </div>
                </div>
                <div class="msg msg-left">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-1.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-left">
                    <div class="message">uwhdfbdsj</div>
                  </div>
                </div>
              </div>
              <div class="record-wrapper">
                <div class="time"></div>
                <div class="msg msg-right">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-2.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-right">
                    <div class="message">26324237</div>
                  </div>
                </div>
                <div class="msg msg-left">
                  <div class="img-wrapper">
                    <img src="../../assets/home/bg-1.png" alt="">
                  </div>
                  <div class="message-wrapper message-wrapper-left">
                    <div class="message">uwhdfbdsj</div>
                  </div>
                </div>
              </div>
            <!--聊天输入框-->
              <div class="info-list-input">
                <div class="detail-input">
                  <!-- <el-input v-model="input" placeholder="Please input" />-->
                  <textarea autofocus name="text" cols="40" rows="3" wrap="hard" placeholder="请输入文字">
                  </textarea>
                </div>
                <div class="detail-btn">
                  <el-button>发送</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import InfoDetailList from '../information/InfoDetailList.vue'
import {ftpUrl} from "@/global/config";
export default {
  name: "InfoList",
  components:{InfoDetailList},
  setup(){
    const activeRef = ref(false);
    const placementRef = ref("right");
    const activate = (place) => {
      activeRef.value = true;
      placementRef.value = place;
    }
    return{
      active: activeRef,
      placement: placementRef,
      activate,
      value: ref(5),
      input:ref(''),
      // count:ref(0),
      item:[
        'https://yqwzh.ink/SchoolCupid/defaultImg/default_headImg.png'
      ]
    }
  }
}
</script>

<style lang="less" scoped>
  .info-draw{
    overflow: hidden;
  }
  .info-list-infoList{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #ededed;
    .info-left{
      display: flex;
      .info-avatar{
        .info-avatar-state{
          position: relative;
          border-radius: 50px;
          width: 15px;
          height: 15px;
          background: red;
          top: 70px;
          left: 35px;
          border: 1px solid #fff;
        }
      }
    }
    .info-tit{
      padding: 0 10px;
    }
    .info-user{
      margin: 0;
      font-size: 16px;
      font-weight: bold;
    }
    .info-state{
      font-size: 14px;
    }
    .info-chatBox{
      position: relative;
      margin: 12px;
      padding: 5px 8px;
      word-break: break-all;
      background: #6be585;
      border: 1px solid #6be585;
      border-radius: 5px;
      max-width: 180px;
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .chatBox-left::before{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 9px;
      left: -20px;
      border: 10px solid;
      border-color: transparent #6be585 transparent transparent;
    }
  }

  //聊天列表区域
  .info-list-infoDetailList {
    overflow: hidden;
    .info-user-float {
      width: 100%;
      //background: #7aa1d2;
      .info-user-left{
        width: 15%;
        height: 100%;
        position: absolute;
        left: 0;
        overflow: auto;
        .user-left-avatar{
          text-align: center;
          border-right: 1px solid #ededed;
          //overflow: auto;
        }
      }
      .info-user-left::-webkit-scrollbar{
        display: none;
      }
      .info-user-right{
        width: 85%;
        height: 70%;
        position: absolute;
        right: 0;
        overflow: auto;
      }
      .info-user-right::-webkit-scrollbar{
        display: none;
      }
      .user-right-list {
        width: 100%;
        right: 0;
        //  聊天页面
        .record-wrapper {
          margin: 4px;
          padding: 4px;
        }
        .time {
          text-align: center;
        }
        .msg {
          display: flex;
          flex-direction: row;
          .message-wrapper {
            max-width: 220px;
            margin: 0px 10px 0px 10px;
            .message {
              margin: 8px;
              word-wrap: break-word; //英文换行
            }
          }
          .message-wrapper-left, .message-wrapper-right {
            position: relative;
            //padding: 5px 8px;
            word-break: break-all;
            background: #6be585;
            border: 1px solid #6be585;
            border-radius: 5px;
            max-width: 180px;
          }
          .message-wrapper-left::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 9px;
            left: -20px;
            border: 10px solid;
            border-color: transparent #6be585 transparent transparent;
          }
          .message-wrapper-right::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 9px;
            right: -20px;
            border: 10px solid;
            border-color: transparent transparent transparent #6be585;
          }
          .img {
            flex: auto;
            height: 36px;
            width: 36px;
            border-radius: 8px;
          }
        }
        .msg-right {
          flex-direction: row-reverse;
          margin: 10px 0;
        }
        .msg-left {
          flex-direction: row;
          margin: 10px 0;
        }
        .img-wrapper img {
          height: 50px;
          width: 50px;
          border-radius: 50px;
        }
      }
    }
    .info-list-input {
      position: fixed;
      bottom: 10px;
      padding: 10px ;
      display: flex;
      justify-content: space-around;
      align-items: end;
      .detail-input{
        width: 80%;
        textarea{
          padding: 10px;
          border: 1px solid #b9bbbe;
          outline: #b9bbbe;
          //text-indent: 24px;//文字首行缩进
        }
      }
      .detail-btn{
        margin: 0 10px;
        el-button{

        }
      }
    }
  }

</style>