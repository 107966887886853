<template>
  <Header v-if="!$route.meta.showNav" ref="HeaderRef"></Header>
  <router-view @hideHead="hideHead" @sengNewMsg="sengNewMsg"></router-view>
  <Footer v-if="!$route.meta.showNav" ></Footer>
  <n-back-top :right="40" :bottom="160">
    <div
        style="
        background-color: rgb(254, 253, 253);
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
      "
    >
      置顶
    </div>
  </n-back-top>
</template>

<script>
import Header from './components/head/Header.vue'
import Footer from './components/foot/Footer.vue'
import Nav from './components/head/Nav.vue'
import {GetDevice} from "../utils/device";
import {ref,getCurrentInstance} from "vue";

export default {
  name: 'App',
  components: {Header, Footer},
  beforeCreate() {

      //收集日志
      let getDevice = GetDevice();
      sessionStorage.setItem("useDev",getDevice.dev)
      sessionStorage.setItem("useOs",getDevice.os)
      this.$requestCenter.accessInfo()


    this.$requestCenter.checkWebToken().then((e) => {
      if (e.data == null) {
        this.hideHead(false)
        this.$router.replace("/welcome")
      }else {
        this.isComplete = e.data.isComplete
      }
    })

  },
  methods: {
    hideHead(e) {
      this.showHeader = e
    },
    sengNewMsg(userId) {
      let params = {
        receiveUserId: userId
      }
      this.$requestCenter.sendFistMsg(params).then((e) => {
        console.log(e)
      })
      this.$refs.HeaderRef.fistSendMsg(userId)
      this.$refs.HeaderRef.fistSendMsg(userId)
    }
  },
  setup() {
    const HeaderRef = ref()
    const isComplete = getCurrentInstance().appContext.config.globalProperties.$isComplete
    return {
      HeaderRef,
      showHeader: ref(false),
      showNav:ref(false),
      isComplete
    }
  },
  created() {
    // alert(6666)

  },
  computed: {
    storage() {
      return this.$store.getters.storage
    },
  }
}
</script>

<style>
@import "../src/assets/base.css";
</style>
